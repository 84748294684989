/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primeicons/primeicons.css";

@font-face {
  font-family: "BCA Sans";
  src:
    url("assets/fonts/BCASans-Regular.woff2") format("woff2"),
    url("assets/fonts/BCASans-Regular.woff") format("woff"),
    url("assets/fonts/BCASans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BCA Sans";
  src:
    url("assets/fonts/BCASans-SemiBold.woff2") format("woff2"),
    url("assets/fonts/BCASans-SemiBold.woff") format("woff"),
    url("assets/fonts/BCASans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BCA Sans";
  src:
    url("assets/fonts/BCASans-Bold.woff2") format("woff2"),
    url("assets/fonts/BCASans-Bold.woff") format("woff"),
    url("assets/fonts/BCASans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BCA Sans";
  src:
    url("assets/fonts/BCASans-Extrabold.woff2") format("woff2"),
    url("assets/fonts/BCASans-Extrabold.woff") format("woff"),
    url("assets/fonts/BCASans-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-family: "BCA Sans" !important;
  --primary-color: #3d489c !important;
  --tertiary-color: #1c2553 !important;
  --dark-color: #1f1f1f !important;
  --gray-color: #919191 !important;
}

// * {
//   font-family: "BCA Sans" !important;
// }

body {
  font-family: "BCA Sans" !important;
  margin: 0 !important;
  line-height: 1.5;
  background: #f5f5f5;
  color: #2b2b2b !important;
}

body {
  &:has(.signin-mask) {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.p,
label {
  margin: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0 !important;
  font-family: "BCA Sans" !important;
  color: #4d5562 !important;
}

// MARK::PRIMENG COMPONENT STYLE
p-dynamicdialog {
  .p-dialog-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .p-dialog-content {
    @apply rounded-3xl p-5;
  }
}

p-tabView {
  .p-tabview,
  .p-tabview-nav,
  .p-tabview-selected,
  .p-tabview-panels,
  .p-tabview-panel {
    background: transparent;
  }
}

p-breadcrumb {
  .p-menuitem-text {
    @apply line-clamp-1 flex items-center text-xs;
    min-height: 16px;
  }
}

.p-carousel {
  .p-carousel-item {
    // margin-right: 16px
    padding-bottom: 8px;
  }

  .p-carousel-indicators {
    padding: 8px;
  }

  .p-carousel-indicator {
    margin: 4px;
    button {
      padding: 0;
      width: 8px;
      height: 8px;
      border-radius: 100px;
    }

    &.p-highlight {
      button {
        background: var(--primary-color) !important;
      }
    }
  }
}

p-dropdown {
  .p-dropdown {
    width: 100%;
    border: 2px solid #dddddd;
  }

  .p-dropdown-clearable {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown-label {
    display: flex;
    padding: 12px;
    font-size: 12px;
  }

  .p-dropdown-trigger {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown-panel {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #fff !important;
  }

  .p-dropdown-items-wrapper {
    border: 1px solid #ccd4e9;
    border-radius: 8px;
    text-align: start;
    font-size: 12px;
    max-height: 250px !important;
  }

  .p-dropdown-items {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown-item {
    &.p-highlight {
      color: #fff !important;
    }

    &.p-focus {
      background: #3d489c;
    }
  }

  .p-dropdown-filter-container {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown-filter {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown-open {
    // Tambahkan gaya di sini jika diperlukan
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: #3d489c;
  }

  &.small {
    .p-dropdown {
      border: 2px solid #dddddd;
    }

    .p-dropdown-label {
      display: flex;
      padding: 5px 8px;
      font-size: 10px;
    }

    .p-dropdown-items-wrapper {
      font-size: 10px;
    }

    .p-dropdown-items {
      padding: 4px 0;
    }

    .p-dropdown-item {
      padding: 4px;
    }

    .p-dropdown-trigger {
      justify-content: end;
      padding-right: 8px;
    }

    .p-dropdown-trigger-icon {
      width: 12px;
    }
  }
}

.p-dropdown {
  width: 100%;
  border: 2px solid #dddddd;
}

.p-dropdown-label {
  display: flex;
  padding: 12px;
  font-size: 12px;
}

.p-dropdown-panel
.p-dropdown-items
.p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
color: #fff !important;
}

.p-dropdown-items {
  padding: 0;
}

.p-dropdown-items-wrapper {
  border: 1px solid #ccd4e9;
  border-radius: 8px;
  text-align: start;
  font-size: 12px;
  max-height: 210px !important;
}

.p-dropdown-item {
  &.p-highlight {
    color: #fff !important;
  }

  &.p-focus {
    background: #3d489c;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #3d489c;
}


p-skeleton {
  .qr-skeleton.p-skeleton {
    height: auto !important;
  }
}

p-calendar {
  .p-calendar {
    font-size: 12px;
    width: 100%;
    border: 1px solid #e5e7eb;
    border-radius: 8px;

    &::placeholder {
      font-size: 12px;
    }
  }

  &.invalid {
    .p-calendar {
      border-color: #c42f35;
    }
  }

  .p-datepicker {
    width: 100%;
  }

  .p-datepicker-header {
    button {
      min-width: unset;
      color: #3d489c;
    }
  }
}

.p-overlaypanel {
  margin-top: 8px !important;
}

.p-overlaypanel:before,
.p-overlaypanel::after {
  display: none;
}

// MARK::FORM STYLE

input {
  &[type="time"],
  &.p-inputtext {
    @apply w-full rounded-lg border border-solid border-[#CCD4E9] p-3 text-xs text-[#1F1F1F];

    appearance: none;
    -webkit-appearance: none;
    color: #1F1F1F;
    -webkit-text-fill-color: #1F1F1F;
    :focus {
      outline: none;
      --tw-ring-color: transparent;
      --tw-ring-shadow: none;

      // Terapkan hanya jika elemen tidak memiliki class .invalid
      &.invalid {
        border-color: #c42f35; // Tetap merah untuk .invalid
      }

      &:not(.invalid) {
        @apply border-[#3d489c];
      }
    }

    &.invalid {
      @apply border-[#C42F35];
    }
  }
}

input[type=time]:not(:valid):before {
  content: "Pilih Jam";
  color: #919191;
  position: absolute;
  top: 3px;
  background: #fff;
  height: 90%;
  align-content: center;
}

textarea {
  font-size: 10px;
  &:focus {
    --tw-ring-color: transparent;
    --tw-ring-shadow: none;
    outline: none;
    @apply border-[#3d489c];
  }
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #fff !important;
}

.field-checkbox label {
  width: 100%;
}

.field-radio label {
  width: 100%;
  text-align: start;
}

.invalid {
  border-color: #ff0000 !important;

  .p-inputtext {
    border-color: #ff0000 !important;
  }

  .p-calendar {
    border-color: #ff0000 !important;
  }

  .p-dropdown {
    border-color: #ff0000 !important;

    .p-dropdown-trigger-icon {
      color: #ff0000 !important;
    }
  }

  .p-editor-container {
    .p-editor-toolbar.ql-snow {
      border: 1px solid #ff0000 !important;
      background: #ff0000 !important;
    }

    .p-editor-toolbar {
      background: #ffeeee;
    }
  }

  .p-editor-content.ql-snow {
    &:has(.ql-editor:focus) {
      border: 2px solid #ff0000 !important;
    }
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #ff0000 !important;
    // border-top: 0px;
  }

  .ql-snow.ql-toolbar button.ql-active {
    .ql-stroke,
    .ql-fill {
      stroke: #ff7d7d !important;
    }
  }

  &.ng-select .ng-select-container {
    border-color: #ff0000 !important;
  }

  .p-checkbox .p-checkbox-box {
    border-color: #ff00007a !important;
  }

  .p-radiobutton .p-radiobutton-box {
    border-color: #ff00007a !important;
  }

  .p-calendar .p-calendar {
    border-color: #ff00007a !important;
  }

  .p-input-icon-right > .p-icon-wrapper,
  .p-input-icon-right > i:last-of-type {
    color: #ff0000 !important;
  }
}

.field-radio:has(.invalid),
.field-checkbox:has(.invalid) {
  border-color: #ff0000 !important;
}

// MARK::CUSTOM STYLE

button {
  padding: 10px;
  font-size: 14px;
  border-radius: 48px;
  font-weight: 700;
  outline: none;
  // min-width: 120px;

  &.primary {
    background: var(--primary-color);
    color: #fff;
    border: 2px solid var(--primary-color);
  }

  &.primary-outline {
    color: var(--primary-color);
    background: #fff;
    border: 2px solid var(--primary-color);
  }

  &.disable {
    background: #dddddd;
    border: 2px solid #919191;
    color: #919191;
    pointer-events: none;
  }
}

.scroll-content-wrapper {
  height: fit-content;
  max-height: calc(100dvh - 52px - 24px - 24px - 72px - 80px);
  overflow-y: auto;

  &.fixed-height {
    overflow-y: hidden;
    height: calc(100dvh - 52px - 24px - 24px - 72px - 80px);
    max-height: unset;
  }
}

.text-primary {
  color: var(--primary-color);
}

.text-gray {
  color: var(--gray-color);
}

.text-dark {
  color: var(--dark-color);
}

.banner-login {
  @apply flex min-h-[400px] flex-col items-center justify-center bg-[url('assets/images/bg-login.jpg')] bg-cover bg-center;
}

.header {
  @apply fixed left-0 top-0 z-50 flex w-full items-center justify-between rounded-b-xl;
  background: var(--primary-color);
  padding: 10px 18px;
}

.content {
  overflow-y: auto;
  height: 100dvh;
  padding: 52px 0 80px 0;
}

.profile-wrapper {
  @apply relative flex items-center justify-center rounded-[23px] bg-[#2E3885];
}

.profile-dropdown {
  // @apply absolute right-0 top-9 z-50 justify-center rounded-md border border-solid border-[#ccd4e9] bg-white px-3 py-2;
  @apply p-3;
}

.img-profile {
  @apply aspect-1 w-8 rounded-full object-cover p-[2px];

  &.big {
    width: 100px;
  }
}

.avatar {
  @apply flex items-center justify-center rounded-full;

  &.big {
    width: 100px;
    height: 100px;
  }
}

.breadcrumb-wrapper {
  .p-breadcrumb {
    padding: 0;
    font-size: 10px;
    border: 0;
    color: var(--gray-color);

    homeicon {
      display: none;
    }
  }

  .p-icon {
    width: 10px;
    color: var(--gray-color);
  }
}

.custom-carousel .p-carousel-item {
  display: flex;
  padding: 0 12px; /* Tambahkan jarak horizontal */
  flex-direction: column;
  box-sizing: border-box;
}

.event-list-homepage {
  @apply me-4 flex min-h-full w-48 flex-shrink-0 flex-col overflow-hidden rounded-xl bg-white;

  &:nth-child(1) {
    margin-left: 12px;
  }

  &:nth-child(5) {
    margin-right: 0 !important;
  }
}

.event-item {
  @apply flex min-h-full flex-col overflow-hidden rounded-xl bg-white;
  box-shadow: 2px 2px 10px 0px #00000026;
}

.box-shadow {
  box-shadow: 2px 2px 10px 0px #00000040;

  &.small {
    box-shadow: 2px 2px 8px 0px #00000026;
  }
}

.event-image {
  width: 100%;
  aspect-ratio: 16/9;
}
.event-details {
}

.event-location,
.event-date {
  font-size: 10px;
  color: var(--dark-color);
}

.custom-tabview {
  .p-tabview-nav {
    @apply justify-between overflow-hidden rounded-xl border-0 bg-white;

    li {
      @apply w-full;
      .p-tabview-nav-link {
        @apply w-full justify-center border-0 text-sm;
      }
      &.p-highlight {
        .p-tabview-nav-link {
          border: none;
          color: var(--primary-color);
          position: relative;

          &::after {
            content: "";
            width: 100%;
            height: 6px;
            background: var(--primary-color);
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }

  .p-tabview-panels {
    margin-top: 12px;
    padding: 0;
  }

  .p-tabview-nav-link {
    background: transparent;
  }
}

.custom-progressbar {
  width: 100%;
  .p-progressbar {
    height: 10px;
    border-radius: 8px;
  }
  .p-progressbar-value {
    background: #ffc530;
    border-radius: 8px;
  }
  .p-progressbar-label {
    display: none;
  }

  &.stepper {
    .p-progressbar {
      height: 4px;
      border-radius: 8px;
    }
    .p-progressbar-value {
      background: #3d489c;
      border-radius: 8px;
    }
  }
}

.custom-rating {
  .p-rating .p-rating-item .p-rating-icon.p-icon {
    // width: 28px;
    // font-size: 28px;
  }

  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #ffc530;
  }
}

.quiz-stepper {
  .p-stepper-nav {
    display: none;
  }

  .p-stepper-panels {
    padding: 0;
  }
}

.quiz-content {
  padding: 0 16px;
  @apply overflow-y-auto text-center;
  height: calc(
    100dvh - 52px - 24px - 24px - 72px - 80px - 24px - 16px - 12px - 40px - 42px
  );

  &.no-button {
    height: calc(
      100dvh - 52px - 24px - 24px - 72px - 80px - 24px - 16px - 12px - 40px
    );
  }

  &.result {
    height: calc(
      100dvh - 52px - 24px - 24px - 72px - 80px - 24px - 16px - 12px - 22px
    );
  }
}

.custom-checkbox {
  position: absolute;
  left: -9999px;
}
.custom-checkbox {
  & + label {
    @apply relative m-0 flex h-full min-h-[50px] w-full cursor-pointer items-center rounded-lg border border-solid border-[#ccd4e9] p-3 text-xs;
    padding-left: calc(1em + 32px);

    &::before {
      content: "";
      @apply absolute left-4 top-[14px] h-4 w-4 border border-solid border-[#919191];
      border-radius: 2px;
      box-sizing: border-box;
    }

    &::after {
      content: "";
      @apply absolute left-4 top-[14px] h-4 w-4 border border-solid border-[#3d489c] bg-[#3d489c];
      border-radius: 2px;
      transition: all 0.2s ease;
      background-image: url("assets/icon/icon-check.svg");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: center;
      opacity: 0;
    }
  }

  &:checked + label {
    background: #fff;
    border: 1px solid #3d489c;

    &::before {
      background: #fff;
    }

    &::after {
      opacity: 1;
    }
  }

  &:disabled + label {
    opacity: 0.5;
  }
}

.custom-radio {
  position: absolute;
  left: -999999px;

  & + label {
    @apply relative flex h-full w-full cursor-pointer items-center rounded-lg border border-solid border-[#CCD4E9] p-3 text-xs leading-6;
    padding-left: 48px;

    &:before {
      content: "";
      @apply absolute left-5 top-4 box-border rounded-full;
      width: 16px;
      height: 16px;
      background: #fff;
      border: 1px solid #919191;
    }

    &:after {
      content: "";
      @apply absolute rounded-full transition-all;
      left: 22px;
      top: 18px;
      width: 12px;
      height: 12px;
      background: #3d489c; /* var(--ion-color-medium) diganti */
      opacity: 0;
    }
  }

  &:checked + label {
    @apply border-[#3D489C];
    opacity: 1;

    &:before {
      border-color: #3d489c;
    }

    &:after {
      opacity: 1;
    }
  }

  &.quiz {
    & + label {
      @apply w-full justify-center overflow-hidden rounded-lg border-2 border-solid border-[#3d489c] p-3 !text-center text-xs leading-none text-[#3d489c];

      &:before {
        content: none;
      }

      &:after {
        content: none;
      }
    }

    &:checked + label {
      background: #e4eaf9;
      font-weight: 400;

      &:before {
        content: none;
      }
    }
  }
  &.error {
    & + label {
      @apply border-2 border-solid border-[#c42f35];
    }
  }
}

.field-checkbox {
  padding: 12px 10px;
  border-radius: 8px;
  border: 1px solid #ccd4e9;
  display: flex;
  justify-content: start;

  &:has(.p-checkbox-box.p-highlight) {
    border: 1px solid #3d489c;
  }

  label {
    margin-left: 8px !important;
    font-size: 12px;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #3d489c;
    background: #3d489c;
  }
}

.field-radio {
  padding: 12px 10px;
  border-radius: 8px;
  border: 1px solid #ccd4e9;

  &:has(.p-radiobutton-box.p-highlight) {
    border: 1px solid #3d489c;
  }

  &.correct {
    background: rgba(37, 147, 61, 0.2);
  }
  &.false {
    background: rgba(196, 47, 53, 0.2);
  }

  label {
    font-size: 10px;
  }

  .p-radiobutton-box.p-highlight {
    border-color: #3d489c;
    background: #fff;
  }

  .p-radiobutton-icon {
    background: #3d489c;
  }
}

.transparent-dialog {
  &.p-dialog {
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
    max-height: 100%;
  }
  .p-dialog-content {
    padding: 0;
    background: transparent !important;
    border-radius: 0;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .p-dialog-header {
    display: none !important;
  }
}

.date-list {
  @apply rounded-lg border-2 border-solid border-[#F5F5F5] bg-white p-3 text-center;

  .days {
    font-size: 10px;
  }

  .date {
    @apply text-dark text-3xl font-bold;
  }

  &:nth-child(1) {
    margin-left: 12px;
  }

  &:nth-last-child(1) {
    margin-right: 12px;
  }

  &.today {
    @apply bg-[#CCD4E9];

    .date,
    .days {
      color: #1c2553;
    }
  }

  &.active {
    @apply border-[#3D489C] bg-[#3D489C];

    .date,
    .days {
      color: #fff;
    }
  }
}

zxing-scanner {
  &.mirror {
    display: flex;

    video {
      pobject-fit: cover;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      border-radius: 8px;
      object-position: center;
    }
  }
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

.swal2-container {
  color: #fff !important;
}

button {
  &.swal2-close {
    color: #fff !important;
  }
}

nav.p-breadcrumb.p-component {
  overflow: visible;

  .p-dropdown {
    border: none;
  }
  /* Menghapus border dan shadow dari dropdown */
  p-dropdown .p-dropdown-label {
    border: none;
    box-shadow: none;
    background-color: transparent; /* Opsional, untuk menghilangkan background */
    padding: 0;
    color: inherit; /* Menggunakan warna teks default */
  }

  /* Menghapus ikon chevron pada dropdown */
  p-dropdown .p-dropdown-trigger {
    display: none;
  }

  /* Menyesuaikan tinggi dropdown agar tetap rapi */
  p-dropdown {
    height: auto;
  }

  p-dropdown .p-dropdown-label.p-placeholder {
    justify-content: center;
    background-color: lightgray;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563 !important;
  }

  p-dropdown .p-dropdown-item.p-focus {
    background: transparent;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563 !important;
    background: #f3f4f6;
  }
}

#tsparticles {
  height: 100%;
}

span.p-dropdown-trigger-icon.pi {
  &.pi-chevron-down,
  &.pi-chevron-up {
    font-size: small;
  }
}

p-checkbox.ng-dirty.ng-invalid:not(.invalid) > .p-checkbox > .p-checkbox-box {
  border-color: #d1d5db !important;
}

.p-timepicker i {
  width: 24px;
  height: 24px;
  color: #3D489C;

  font-size: 20px;
  font-weight: 500;

  text-align: center;
  align-items: center;
  align-content: center;
}

swiper-container::part(pagination) {
  position: unset !important;
}

swiper-container::part(bullet-active) {
  background: var(--primary-color) !important;
}

.p-datepicker table td > span.p-highlight {
  color: white;
  background: #3D489C;

  i.pi.pi-circle-fill.text-\[4px\].ng-star-inserted {
    display: none;
  }
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: transparent;
}
